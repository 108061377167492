@-webkit-keyframes slideInMenu {
  0% {
    opacity: 0;;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slideInYslideInMenu {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}