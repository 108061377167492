*, *:before, *:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  font-family: $font-sans;
  font-size: 1.4rem;
  color: $grey-darkest;
  background-color: $grey-lightest;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 2rem 0;
}

a {
  color: $purple-darker;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.hidden {
  &.desktop {
    @include desktop {
      display: none !important;
    }
  }
}

.floated {
  @include desktop {
    &.right { float: right; }
    &.left  { float: left; }
  }
}

.toggle-sidebar {
  display: inline-block;
  width: 1.7rem;
  height: 1.1rem;
  margin-right: 1rem;
  font-size: 0;
  background: url(../images/menu.svg) center top no-repeat;
  vertical-align: middle;

  @include desktop {
    display: none;
  }
}
