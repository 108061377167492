// Colors
$grey-darkest: #111111;
$grey-darkerer: #333333;
$grey-darker: #4A4A4A;
$grey-dark: #979797;
$grey: #9B9B9B;
$grey-light: #CCCCCC;
$grey-lighter: #D8D8D8;
$grey-lighterer: #EEEEEE;
$grey-lightest: #F4F4F4;

$blue-dark: #2765AE;
$blue: #4080CA;
$blue-light: #4A90E2;

$purple-darker: #4A0C80;
$purple-dark: #6C0CC0;
$purple: #9630D5;
$purple-light: #C982DC;

$orange: #E7C64E;
$orange-dark: #F7A01C;
$orange-darker: #F1B045;

$red-darker: #9F041B;
$red-dark: #C30A1B;
$red: #D0021B;
$red-light: lighten($red, 20%);
$red-lighter: #FFF6F6;

// Typography
$font-sans: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",sans-serif;

// Dimensions
$border-radius: 2px;
$navbar-height: 5.6rem;
$sidebar-width: 20rem;

// Responsive
$desktop-width: 768px;
$desktop-wide-width: 1280px;
