.navbar {
  position: relative;
  height: 5.6rem;
  margin-bottom: 3rem;

  a {
    color: $grey-darker;
    transition: opacity 0.1s linear;

    &:hover {
      opacity: 0.7;
      text-decoration: none;
    }
  }

  h2 {
    flex: 1;
    margin-top: 2rem;
    padding: 0 2rem;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1em;
    text-align: center;

    .switch-website {
      display: inline-block;
      color: $grey-darker;

      .ui.dropdown {
        padding: 0;

        .text {
          margin: 0;
          color: $grey-darker;
        }

        .transition.menu {
          flex-direction: column;
          margin-top: 1rem;
          box-shadow: 0 2px 4px rgba(0,0,0,0.5);
          border-radius: 0.5rem;
          border-width: 1px;
          min-width: 34rem;
          overflow: hidden;
          left: 50% !important;
          right: auto !important;
          transform: translateX(-15.6rem);
          animation: slideInMenu;

          @include tiny-mobile {
            min-width: 30rem;
            transform: translateX(-13.6rem);
          }
        }

        .scrolling.menu {
          border-radius: 0 !important;
        }

        .header {
          font-size: 1rem;
          letter-spacing: 1px;
        }

        .active.item {
          color: $grey;
          background-color: $grey-lightest;
          font-weight: 500;

          &:before {
            display: inline-block;
            content: '';
            width: 0.8rem;
            height: 0.8rem;
            border-radius: 100%;
            background-color: $purple-light;
            margin-right: 0.5rem;
          }
        }

        &:before {
          content: url(../images/switch.svg);
          margin-right: 1rem;
          vertical-align: middle;
        }
      }
    }

    a {
      color: $grey-darker;
    }

    .edit-website {
      content: url(../images/settings.svg);
      margin-left: 0.8rem;
      vertical-align: text-bottom;
    }

    .last-updated {
      margin-top: 0.8rem;
      font-size: 1rem;
      font-weight: normal;
      color: $grey;
    }

    time {
      font-weight: bold;
    }
  }

  .account {
    display: block;
    position: absolute;
    left: 1rem;
    top: 50%;
    margin-top: -1.8rem;
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 100%;
    overflow: hidden;

    img {
      width: 100%;
      vertical-align: middle;
    }
  }

  .action {
    position: absolute;
    right: 1rem;
    top: 50%;
    margin-top: -1.7rem;
    min-width: 0;
    padding: 0.9rem 1rem;
    font-size: 1.2rem;
    background: transparent;
    border: 1px solid $grey-light;

    @include desktop {
      min-width: 11.1rem;
    }

    &.hidden {
      display: none;
    }

    span {
      display: none;

      @include desktop {
        display: inline-block;
      }
    }

    &:before {
      display: inline-block;
      content: url('../images/view.svg');
      vertical-align: middle;

      @include desktop {
        margin-right: 0.5rem;
      }
    }

    &.build {
      @extend .orange;

      &:hover {
        opacity: 1;
      }

      &:before {
        content: url('../images/build.svg');
      }
    }
  }
}