.sidebar {
  display: flex;
  flex-direction: column;
  width: $sidebar-width;
  background: $grey-lightest;
  overflow: scroll;
  padding-top: 0.5rem;

  > div:first-child {
    flex: auto;
  }

  .header {
    padding: 2rem 2rem 0.7rem;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.142857142857143em;
  }

  .item:first-of-type .header {
    padding-bottom: 1.4rem;
    font-size: 1.4rem;
    text-transform: none;
  }

  .menu {
    .item {
      position: relative;
      display: block;
      padding: 1rem 1.7rem 1rem 4.3rem;
      text-decoration: none;
      color: $grey-dark;
      border-radius: 3px 0 0 3px;

      svg {
        position: absolute;
        top: 50%;
        left: 2rem;
        margin-top: -0.9rem;

        g {
          stroke: $grey-dark;
        }
      }

      &:hover,
      &.active {
        color: $grey-darkest;

        g {
          stroke: $grey-darkest;
        }
      }

      &.active {
        color: white;
        background-color: $grey-darkerer;

        g, rect {
          stroke: white;
          opacity: 1;
        }
      }
    }
  }
}
