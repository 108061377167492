.form {
  input,
  textarea,
  .wysiwyg {
    font-family: inherit;
    font-size: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
  }

  input:not(.button),
  textarea {
    display: block;
    width: 100%;
    padding: 1rem 1rem 1.1rem;
    border: 1px solid $grey-light;
    border-radius: $border-radius;

    &::-webkit-input-placeholder { color: $grey; }
    &::-moz-placeholder { color: $grey; }
    &:-ms-input-placeholder { color: $grey; }
    &:-moz-placeholder { color: $grey; }
  }

  .wysiwyg,
  .ace_editor {
    font-size: inherit;
    min-height: 10rem;
    border: 1px solid $grey-light;
    border-radius: $border-radius;
  }

  .ql-toolbar {
    border-radius: $border-radius $border-radius 0 0;
  }

  .wysiwyg {
    border-radius: 0 0 $border-radius $border-radius;
  }

  .field,
  .fields {
    margin-bottom: 4rem;

    &.submit {
      margin-top: -2rem;
      margin-bottom: 0;
    }

    &.inline {
      margin-bottom: 0;

      input.button {
        margin-top: 1rem;
      }

      @include desktop {
        display: flex;
        margin-bottom: 1em;

        input.button {
          margin-top: 0;
          margin-left: 1rem;
        }
      }
    }

    label {
      display: block;
      font-size: 1.6rem;
      font-weight: 600;
      margin-bottom: 1rem;

      &.hidden {
        font-size: 0;
        width: 0;
        margin-bottom: 0;
      }

      + small {
        margin-top: -1rem;
        margin-bottom: 1rem;
      }
    }

    &.required label:after {
      display: inline-block;
      content: '*';
      color: $red;
      margin-left: 0.5rem;
    }

    > small {
      display: block;
      font-size: 1.2rem;
      color: $grey-dark;

      &.error-message {
        color: $red;
        margin-top: 0.5rem;
      }
    }

    &.error {
      .wysiwyg,
      .ace_editor,
      .previewable,
      .dropdown.selection,
      .ui.checkbox label:before,
      input:not(.button) {
        background-color: $red-lighter;
        border-color: $red;
      }

      .ql-toolbar {
        border-top-color: $red;
        border-right-color: $red;
        border-left-color: $red;
      }
    }
  }

  div.previewable {
    padding: 1em;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: $border-radius;

    input:first-of-type {
      padding: 0;
      border: 0;
    }

    .preview {
      display: block;
      margin: 1rem 0;
      max-width: 150px;
      max-height: 150px;
    }
  }

  /**
   * Semantic UI overrides
   */
  .ui.checkbox {
    display: block;
    margin-bottom: 0.5rem;

    &:after {
      display: none;
    }
  }

  .ui.label:hover {
    text-decoration: none;
  }
}

