$toggle: -20rem;

body.dashboard {
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
  }

  /**
   * Mobile menu
   */
  .sidebar,
  main {
    transition: margin 0.15s ease-out;
  }

  .sidebar {
    margin-left: $toggle;
  }

  main {
    margin-right: 0;

    /* Hack */
    max-width: 95%;
    @media screen and (min-width: 470px) {
      max-width: 100%;
    }
  }

  &.sidebar-open {
    .sidebar {
      margin-left: 0;
    }

    main {
      margin-right: $toggle;
    }

    > div {
      overflow-x: hidden;
    }
  }

  @include desktop {
    .sidebar {
      margin-left: 0 !important;
    }

    main {
      margin-right: 0 !important;
    }
  }

  .rail {
    &.left {
      padding-left: 1rem;
    }

    &.right {
      padding-right: 1rem;
    }

    @include desktop {
      &.left {
        width: 21rem;
      }
    }

    @include desktop-wide {
      &.left {
        width: 27rem;
        padding-left: 7rem;
      }

      &.right {
        width: 27rem;
        padding-right: 7rem;
      }
    }
  }

  main {
    flex: 1;
    min-height: 85vh;
  }
}
