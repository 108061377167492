body.auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $grey-lightest;

  main {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    background: none;
    box-shadow: none;

    width: 100%;
    max-width: 50rem;
    margin: 0 auto;
    padding: 0 2rem;

    > {
      flex: none;
    }
  }

  .form {
    .field {
      margin-bottom: 1rem;
    }

    input:not(.button) {
      border-color: transparent;

      &:focus {
        border-color: $grey-lighterer;
      }
    }
  }

  .logo {
    margin: 2rem auto;
    width: 6rem;

    path {
      fill: $grey;
    }
  }

  .actions {
    margin-top: 2rem;
    font-size: 1.2rem;
    text-align: center;

    a.item {
      color: $grey-dark;
    }
  }
}