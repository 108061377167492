body.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $grey-lightest;

  main {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    text-align: center;
    background: none;
    box-shadow: none;

    h1 {
      margin-top: 0;
    }
  }

  .logo {
    margin: 2rem auto;
    width: 6rem;

    path {
      fill: $grey;
    }
  }

  br {
    display: none;

    @include desktop {
      display: block;      
    }
  }

  &.trace {
    display: block;

    pre, .table {
      font-size: 1.4rem;
    }
  }
}